import React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import { Container, Row, Col } from "react-bootstrap";
import TextJumbotron from "../components/util/textJumbotron";

export default function CovidPage({ data }) {
  return (
    <BaseLayout pageTitle="GMA Registration">
      <TextJumbotron heading="Registration" />
      <Container>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
